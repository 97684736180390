import { defineStore } from 'pinia';
import axios from 'axios';

// export const useCalculatorStore = defineStore('calculator', {
//   state: () => ({ calculatorData: null, calculatorSave: null }),

//   getters: {
//     // doubleCount: (state) => state.count * 2,
//   },
//   actions: {
//     async loadCalculatorData(payload) {
//       const { data } = await axios.post('http://back.payseracredit.ge/public/api/calculate/', payload);
//       this.calculatorData = data;
//     },
//     async CalculatorDataSave(payload) {
//       const { data } = await axios.post('http://back.payseracredit.ge/public/api/save/', payload);
//       this.calculatorSave = data;
//     },
//   },
// });

// export const useCalculatorStore = defineStore('calculator', {
//   state: () => ({ calculatorData: null, calculatorSave: null }),

//   actions: {
//     async loadCalculatorData(payload) {
//       try {
//         let token = localStorage.getItem('token');
//         token = '1xPhAx9drtFbjvxPL6NvoVtCB5Uln1kDRMseCcG4bl6pVke9nlZtMbfeQT7Vgv5r';
//         const { data } = await axios.post('http://back.payseracredit.ge/public/api/calculate/', payload, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         this.calculatorData = data;
//       } catch (error) {
//         console.error('Error loading calculator data:', error);
//       }
//     },

//     async CalculatorDataSave(payload) {
//       try {
//         let token = localStorage.getItem('token');
//         token = '1xPhAx9drtFbjvxPL6NvoVtCB5Uln1kDRMseCcG4bl6pVke9nlZtMbfeQT7Vgv5r';
//         const { data } = await axios.post('http://back.payseracredit.ge/public/api/save/', payload, {
//           headers: {
//             Authorization: `Bearer ${token}`, // Include token in Authorization header
//           },
//         });
//         this.calculatorSave = data;
//       } catch (error) {
//         console.error('Error saving calculator data:', error);
//         // Handle error gracefully, e.g., show a message to the user
//       }
//     },
//   },
// });

async function getCsrfToken() {
  try {
    const response = await axios.get('http://back.payseracredit.ge/public/csrf-token');
    return response.data.csrfToken;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    throw error;
  }
}

export const useCalculatorStore = defineStore('calculator', {
  state: () => ({ calculatorData: null, calculatorSave: null }),

  actions: {
    async loadCalculatorData(payload) {
      try {
        const csrfToken = await getCsrfToken();
        const { data } = await axios.post('http://back.payseracredit.ge/public/api/calculate', payload, {
          headers: {
            'X-CSRF-TOKEN': csrfToken,
          },
        });
        this.calculatorData = data;
      } catch (error) {
        console.error('Error loading calculator data:', error);
      }
    },

    async CalculatorDataSave(payload) {
      try {
        const csrfToken = await getCsrfToken();
        const { data } = await axios.post('http://back.payseracredit.ge/public/api/save', payload, {
          headers: {
            'X-CSRF-TOKEN': csrfToken,
          },
        });
        this.calculatorSave = data;
      } catch (error) {
        console.error('Error saving calculator data:', error);
      }
    },
  },
});
