<template>
  <div class="personal-container container">
    <div class="personal-page-info">
      <div class="personal-title">პირადი მონაცემები</div>
      <div class="personal-info">
        <div @click="toggleUp = true" class="personal-toggle" v-click-outside-element="() => (toggleUp = false)">
          <input type="tel" v-model="user.id_num" :class="{ 'error-border': !isInputValid }" @input="validateInput" />
          <span class="personal-name" :class="{ top: toggleUp || user.id_num }"
            >პირადი ნომერი <span v-if="!toggleUp" class="red">*</span></span
          >
        </div>
        <div
          @click="toggleUpName = true"
          class="personal-toggle"
          v-click-outside-element="() => (toggleUpName = false)"
        >
          <input type="text" v-model="user.first_name" />
          <span class="personal-name" :class="{ top: toggleUpName || user.first_name }">სახელი </span>
        </div>
        <div
          @click="toggleUpLastName = true"
          class="personal-toggle"
          v-click-outside-element="() => (toggleUpLastName = false)"
        >
          <input type="text" v-model="user.last_name" />
          <span class="personal-name" :class="{ top: toggleUpLastName || user.last_name }">გვარი </span>
        </div>
        <div
          @click="toggleUpEmail = true"
          class="personal-toggle"
          v-click-outside-element="() => (toggleUpEmail = false)"
        >
          <input
            type="email"
            v-model="user.email"
            :class="{ 'warning-border': !isEmailValid }"
            @input="validateEmail"
          />
          <span class="personal-name" :class="{ top: toggleUpEmail || user.email }">მეილი </span>
        </div>
        <div
          @click="toggleUpMobile = true"
          class="personal-toggle"
          v-click-outside-element="() => (toggleUpMobile = false)"
        >
          <input type="tel" v-model="user.mobile" :class="{ 'error-border': !isNumberValid }" @input="validateNumber" />
          <span class="personal-name" :class="{ top: toggleUpMobile || user.mobile }"
            >ტელეფონი <span v-if="!toggleUpMobile" class="red">*</span></span
          >
        </div>

        <div class="error-mesage-cont">
          <span v-if="idError" class="error-message">{{ idError }}</span>
          <span v-else class="email-error">{{ emailError }}</span>
        </div>
      </div>
    </div>
    <div class="personal-loan-info">
      <div class="personal-loan">სესხის დაანგარიშება</div>
      <div class="loan-calculation">
        <div class="loan-inputs">
          <div
            @click="toggleUpLoanMoney = true"
            class="personal-toggle geovaluta-cont"
            v-click-outside-element="() => (toggleUpLoanMoney = false)"
          >
            <input class="loan-money geoval-input" type="tel" v-model="user.loan_money" @click="handleSelectChange" />
            <img class="geovalutasvg" :src="getImageSource" alt="" />
            <span class="personal-name" :class="{ top: toggleUpLoanMoney || user.loan_money }">სესხის თანხა </span>
            <input type="hidden" name="_token" v-model="csrfToken" />
          </div>

          <select class="padd-top" v-model="selectedValut" @click="handleSelectChange">
            <option value="gel">ლარი</option>
            <option value="usd">დოლარი</option>
            <option value="euro">ევრო</option>
          </select>
          <div
            @click="toggleUpLoanTerm = true"
            class="personal-toggle padding-top"
            v-click-outside-element="() => (toggleUpLoanTerm = false)"
          >
            <input type="tel" class="" v-model="user.LoanTerm" @click="handleSelectChange" />
            <span class="personal-name" :class="{ top: toggleUpLoanTerm || user.LoanTerm }">სესხის ვადა </span>
            <span class="month-span">თვე</span>
          </div>

          <input type="range" min="0" max="240" v-model="user.LoanTerm" @click="handleSelectChange" />
          <div
            @click="toggleUpAdvancePayment = true"
            class="personal-toggle padding-top"
            v-click-outside-element="() => (toggleUpAdvancePayment = false)"
          >
            <input type="tel" class="" v-model="user.advancePayment" @click="handleSelectChange" />
            <span class="personal-name" :class="{ top: toggleUpAdvancePayment || user.advancePayment }"
              >წისწარი შენატანი
            </span>
            <img class="geovalutasvg" :src="getImageSource" alt="" />
          </div>
          <input type="range" min="0" max="1000" v-model="user.advancePayment" @click="handleSelectChange" />
          <div class="loan-moreinfo">
            <img class="geoval" src="../assets/image/info.svg" alt="" />
            <p>სესხის წლიური საპროცენტო განაკვეთი 11.5%-დან, ხოლო ეფექტური 13.5%-დან.</p>
          </div>
        </div>

        <div class="loan-results">
          <div class="loan-buttons-cont">
            <div class="loan-month-result">
              <span>ყოველთვიური შესატანი</span>
              <div class="geoval-cont">
                <span class="loan-number"> {{ calculatorData?.monthly_amount || calculatorSave }} </span>
                <img v-if="calculatorData?.monthly_amount" class="geoval" :src="getImageSource" alt="" />
              </div>
            </div>
            <div class="loan-month-result">
              <span>სულ გადასახდელი</span>
              <div class="geoval-cont">
                <span class="loan-number">{{ calculatorData?.sum_amount }} </span>
                <img v-if="calculatorData?.sum_amount" class="geoval" :src="getImageSource" alt="" />
              </div>
            </div>
          </div>
          <div class="cookies">
            <input type="checkbox" />
            <span>ვეთანხმები <span class="conditions">წესებსა და პირობებს</span> <span class="Snowflake">*</span></span>
          </div>
          <div class="button-cont">
            <Button :disabled="isDisabled" @click="saveData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Button from '@/components/Button.vue';
import { ref, computed, onMounted } from 'vue';
import { useCalculatorStore } from '@/store/calculator';
import axios from 'axios';

const store = useCalculatorStore();

const calculatorData = computed(() => store.calculatorData);
const calculatorSave = computed(() => store.calculatorSave);

const loadData = () => {
  store.loadCalculatorData({
    type: 'mortgage',
    loan_amount: user.value.loan_money,
    months: user.value.LoanTerm,
    currency: selectedValut.value,
    down_payment: user.value.advancePayment,
  });
};
const saveData = () => {
  store.CalculatorDataSave({
    type: 'mortgage',
    loan_amount: user.value.loan_money,
    months: user.value.LoanTerm,
    currency: selectedValut.value,
    down_payment: user.value.advancePayment,
    id_number: user.value.id_num,
    name: user.value.first_name,
    last_name: user.value.last_name,
    email: user.value.email,
    mobile_num: user.value.mobile,
  });
};
const selectedValut = ref('gel');
const isInputValid = ref(true);
const isEmailValid = ref(true);
const isNumberValid = ref(true);
const toggleUp = ref(false);
const toggleUpName = ref(false);
const toggleUpLastName = ref(false);
const toggleUpEmail = ref(false);
const toggleUpMobile = ref(false);
const toggleUpLoanMoney = ref(false);
const toggleUpLoanTerm = ref(false);
const toggleUpAdvancePayment = ref(false);

function handleSelectChange() {
  // Introduce a 3-second delay before calling loadData
  setTimeout(loadData, 1000);
}

const user = ref({
  id_num: '',
  first_name: '',
  last_name: '',
  email: '',
  mobile: '',
  loan_money: '',
  LoanTerm: '',
  advancePayment: '',
});
const isDisabled = computed(() => {
  return (
    !user.value.id_num ||
    !user.value.mobile ||
    !user.value.first_name ||
    !user.value.last_name ||
    !user.value.loan_money
  );
});
const getImageSource = computed(() => {
  return require(`../assets/image/${selectedValut.value}.svg`);
});

const validateInput = () => {
  isInputValid.value = user.value.id_num.trim() !== '';
};
const validateEmail = () => {
  isEmailValid.value = user.value.email.trim() !== '';
};
const validateNumber = () => {
  isNumberValid.value = user.value.mobile.trim() !== '';
};
const idError = computed(() => {
  if ((user.value.id_num && user.value.id_num.length < 11) || (user.value.mobile && user.value.mobile.length < 9)) {
    return 'ველების შევსება სავალდებულოა!';
  }
  return '';
});
const emailError = computed(() => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(user.value.email) && user.value.email) {
    return 'მეილი უნდა შეიცავდეს “@” სიმბოლოს.';
  }
  return '';
});

// onMounted(async () => {
//   const token = window.localStorage.getItem('token');
//   if (token) {
//     await store.calculatorData(token);
//     user.value = { ...store.calculatorSave };
//   }
// });

const csrfToken = ref('');

const fetchCsrfToken = async () => {
  try {
    const response = await axios.get('http://back.payseracredit.ge/public/csrf-token');
    csrfToken.value = response.data.csrfToken;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
  }
};

onMounted(fetchCsrfToken);
</script>

<style>
.container {
  max-width: 1069px;
  margin: auto;
}
.personal-container {
  margin-top: 28px;
  padding: 24px;
  border-radius: 10px;
  background: #f3fafe;
}
.personal-page-info {
  padding: 24px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(196, 196, 196, 0.56);
}
.personal-title,
.personal-loan {
  margin-bottom: 24px;
}
.personal-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 27px;
}
.personal-info input {
  padding: 15px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 152, 0.31);
  margin-bottom: 24px;
  outline: none;
}
.personal-toggle {
  display: flex;
  position: relative;
}

.personal-toggle .month-span {
  position: absolute;
  right: 14px;
  top: 13px;
}
.personal-toggle .personal-name {
  position: absolute;
  top: 15px;
  left: 15px;
  color: #707070;
  font-size: 12px;
  transition: 0.5s;
}
.personal-toggle .top {
  position: absolute;
  top: -6px;
  left: 15px;
  background: #ffffff;
  padding: 0px 9px;
  font-size: 10px;
  transition: 0.5s;
}

.personal-toggle input {
  width: 100%;
}
.personal-toggle .red {
  color: #e8112d;
}
.personal-info .error-border {
  border-color: red;
}
.personal-info .warning-border {
  border-color: #e5a500;
}
.personal-info .email-error {
  color: #e5a500;
  font-family: 'BPG Arial';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.loan-calculation {
  display: grid;
  grid-template-columns: 1.3fr 2fr;
}
.personal-loan-info {
  margin-top: 24px;
  padding: 24px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(196, 196, 196, 0.56);
}
.loan-inputs {
  border-right: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  padding-right: 43px;
}

.loan-inputs .padd-top {
  margin-top: 24px;
}
.loan-results {
  display: flex;
  flex-direction: column;
  padding-left: 43px;
}
@media (max-width: 768px) {
  .loan-results {
    padding-left: 0px;
  }
}
.loan-inputs input,
select {
  outline: none;
  padding: 15px 50px 15px 15px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 152, 0.31);
}
.loan-inputs .loan-month-range {
  position: relative;
  display: flex;
}
.loan-inputs .loan-month-range input {
  width: 100%;
}
.loan-inputs .loan-month-range .month-span {
  position: absolute;
  right: 14px;
  top: 35px;
}
input[type='range'] {
  padding: 0px;
  margin-top: 8px;
  color: #e8112d;
}
/* .loan-money {
  background-image: url('../assets/image/geovaluta.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer;
} */
.geovaluta-cont {
  display: flex;
  position: relative;
}
.geovaluta-cont .geoval-input {
  width: 100%;
}
.geovalutasvg {
  width: 23px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.loan-month-result {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 152, 0.31);
  background: #f9f9fd;
  max-width: 236px;
  width: 100%;
  padding: 16px 40px 16px 16px;
}
.geoval {
  width: 24px;
}
.geoval-cont {
  display: flex;
  margin-top: 11px;
}
.loan-number {
  color: #000;
  font-family: 'BPG Arial';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cookies {
  display: flex;
  align-items: center;
  gap: 5px;
}
.loan-buttons-cont {
  margin-bottom: 35px;
  display: flex;
  column-gap: 32px;
  justify-content: space-between;
}
.conditions {
  color: #3399fe;
  cursor: pointer;
}
.Snowflake {
  color: #e8112d;
}
.loan-moreinfo {
  display: flex;
  gap: 5px;
  align-items: center;
}
.loan-moreinfo p {
  color: #535353;
  font-family: 'BPG Arial';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.button-cont {
  display: flex;
  justify-content: end;
  margin-top: 125px;
}
.error-message {
  color: #ec1c24;
  font-family: 'BPG Arial';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.error-mesage-cont {
  display: flex;
  justify-content: center;
  padding-top: 13px;
}
.auto-loan {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}
.padding-top {
  margin-top: 24px;
}
</style>
