<template>
  <div class="container">
    <div class="navigation-cont">
      <span @click="setActiveButton('mortgage')" :class="{ active: activeButton === 'mortgage' }">იპოთეკური სესხი</span>
      <span @click="setActiveButton('auto')" :class="{ active: activeButton === 'auto' }">ავტო სესხი</span>
      <span @click="setActiveButton('consumer')" :class="{ active: activeButton === 'consumer' }"
        >სამომხმარებლო სესხი</span
      >
    </div>
    <div class="navigation-cont mobile-navigation">
      <span @click="setActiveButton('mortgage')" :class="{ active: activeButton === 'mortgage' }">იპოთეკური</span>
      <span @click="setActiveButton('auto')" :class="{ active: activeButton === 'auto' }">ავტო</span>
      <span @click="setActiveButton('consumer')" :class="{ active: activeButton === 'consumer' }">სამომხმარებლო</span>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';

const emit = defineEmits(['navigationState']);
const activeButton = ref('mortgage');
const setActiveButton = (button) => {
  activeButton.value = button;
  console.log(activeButton.value);
  emit('navigationState', activeButton.value);
};
</script>

<style>
.navigation-cont {
  display: flex;
  max-width: 1069px;
  width: 100%;
  justify-content: space-between;
  background: rgba(58, 125, 255, 0.12);
  border-radius: 12px;
}
@media (max-width: 768px) {
  .navigation-cont {
    display: none;
    align-items: center;
  }
}

.navigation-cont span {
  cursor: pointer;
  color: #000;
  padding: 10px 100px;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .navigation-cont span {
    padding: 10px 10px;
    font-size: 12px;
  }
}

.active {
  background: var(--paysera-blue, #000098);
  color: #fff !important;
  border-radius: 12px;
  transition: 0.4s;
}
@media (max-width: 768px) {
  .active {
    border-radius: 10px;
    /* font-size: 13px; */
  }
}

.mobile-navigation {
  display: none;
}
@media (max-width: 768px) {
  .mobile-navigation {
    display: flex;
    justify-content: space-evenly;
  }
}
</style>
