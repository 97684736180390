<template>
  <button class="request-button">მოითხოვე სესხი</button>
</template>

<script setup></script>

<style>
.request-button {
  cursor: pointer;
  border-radius: 8px;
  background: #7878dd;
  padding: 14px 72px;
  border: none;
  color: white;
}
.request-button:hover {
  background-color: #000098;
}
.request-button:disabled {
  background: #bdbde2;
  cursor: not-allowed;
}
</style>
