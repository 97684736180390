<template>
  <div class="container">
    <Navigation @navigationState="loanType = $event" />
    <MortgageLoan v-if="loanType === 'mortgage'" />
    <AutoLoan v-if="loanType === 'auto'" />
    <ConsumerLoan v-if="loanType === 'consumer'" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Navigation from '../components/Navigation.vue';
import MortgageLoan from '../components/MortgageLoan.vue';
import ConsumerLoan from '../components/ConsumerLoan.vue';
import AutoLoan from '../components/AutoLoan.vue';

const loanType = ref('mortgage');
// console.log(loanType.value);
</script>

<style>
.container {
  max-width: 1069px;
  margin: auto;
}
</style>
