import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import axios from 'axios';
import vueClickOutsideElement from 'vue-click-outside-element';

const pinia = createPinia();
const app = createApp(App);

app.config.globalProperties.axios = axios;
app.use(router).use(vueClickOutsideElement).use(pinia).mount('#app');
